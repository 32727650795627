import React from 'react';
import {Routes, Route, Navigate} from 'react-router-dom';
import Home from '../pages/Home';
import About from '../pages/About';
import Studio from '../pages/Studio';
import Contact from '../pages/Contact';


const Routers = () => {
  return (
            <Routes>
                    <Route path='/' element={<Navigate to='/Home'/>} />
                <Route path='home' element={<Home/>}/> 
                <Route path='about' element={<About/>}/> 
                <Route path='studio' element={<Studio/>}/>
                <Route path='contact' element={<Contact/>}/> 
            </Routes>
  )
}

export default Routers