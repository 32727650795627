
import React from 'react'
import '../components/Styles/home.css'
import m1 from '../images/m1.png'
import m2 from '../images/m2.png'
import m3 from '../images/m3.png'
import m4 from '../images/m4.png'
import m5 from '../images/m5.png'
import m6 from '../images/m6.png'
import m7 from '../images/m7.png'
import m8 from '../images/m8.png'
const Studio = () => {
  return (
    <div>
        <section className='top__section'>
         <h1>Studio</h1>
        </section>

        <section className='center__main'>
    

            <p>In the magical realm of Trailblazer Films, we wield the power of storytelling to create extraordinary cinematic experiences. From the inception of captivating ideas in pre-production to the seamless orchestration of production and the enchantment of post-production, our passionate team of visionary filmmakers, editors, and artists bring dreams to life. With a commitment to innovation and a strategic approach to distribution and marketing, we ensure our creations find their place in the hearts of audiences worldwide. Embracing emerging technologies, we continuously push the boundaries of storytelling, inviting collaborators and viewers to embark on an unforgettable journey through the captivating world of cinema.</p>

                
            <div className='row'>
                
                <div className='card_col'>
                <h3>studio space</h3>
                <img src={m1} alt="m1" />
               </div>
               
                <div className='card_col'>
                   <h3>Location</h3>
                    <img src={m2} alt="m1" />
                </div>
               
                <div className='card_col'>
                   <h3>SETS</h3>
                    <img src={m3} alt="m1" />
                </div>
               
                <div className='card_col'>
                   <h3>PROPS</h3>
                    <img src={m4} alt="m1" />
                </div>
               
                <div className='card_col'>
                   <h3>CREW</h3>
                    <img src={m5} alt="m1" />
                </div>
               
                <div className='card_col'>
                   <h3>GEAR</h3>
                    <img src={m6} alt="m1" />
                </div>
               
                <div className='card_col'>
                   <h3>GEAR</h3>
                    <img src={m7} alt="m1" />
                </div>
               
                <div className='card_col'>
                   <h3>STYLIST</h3>
                    <img src={m8} alt="m1" />
                </div>
           
           </div>
           
        
        </section>
        <section className='center__main2'>

        <p>In the magical realm of Trailblazer Films, we wield the power of storytelling to create extraordinary cinematic experiences. From the inception of captivating ideas in pre-production to the seamless orchestration of production and the enchantment of post-production, our passionate team of visionary filmmakers, editors, and artists bring dreams to life. With a commitment to innovation and a strategic approach to distribution and marketing, we ensure our creations find their place in the hearts of audiences worldwide. Embracing emerging technologies, we continuously push the boundaries of storytelling, inviting collaborators and viewers to embark on an unforgettable journey through the captivating world of cinema.</p>
   <div className='img__bg'>
    
   <h3>studio space</h3>
   </div>
   <div className='img__bg2'>
    
    <h3>Location</h3>
    </div>

    <div className='img__bg3'>
    
    <h3>sets</h3>
    </div>

    <div className='img__bg4'>
    
    <h3>props</h3>
    </div>
    <div className='img__bg5'>
    
    <h3>crew</h3>
    </div>
    <div className='img__bg6'>
    
    <h3>gear</h3>
    </div>

    <div className='img__bg7'>
    
    <h3>gear</h3>
    </div>
    <div className='img__bg8'>
    
    <h3>stylist</h3>
    </div>


</section>

    </div>
  )
}

export default Studio