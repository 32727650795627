
import React from 'react'
import '../components/Styles/home.css'
import m1 from '../images/m1.png'
import m2 from '../images/m2.png'
import m3 from '../images/m3.png'
import m4 from '../images/m4.png'
import m5 from '../images/m5.png'
import m6 from '../images/m6.png'
import m7 from '../images/m7.png'
import m8 from '../images/m8.png'
const Home = () => {
  return (
    <div>
        <section className='top__section'>
            <h1>Ignite your imagination with Trailblazer Films:<br/> Where Stories Come Alive.
</h1>
        </section>

        <section className='center__main'>
    
            <h2>Our Vision:</h2>
            <p>At Trailblazer Films, our vision is to be the pioneers of the film industry, leading the way in shaping the future of storytelling. We strive to create groundbreaking narratives that push the boundaries of conventional cinema, utilizing innovative techniques and technologies to captivate audiences around the world. 
</p>

            <div className='row'>
                
                 <div className='card_col'>
                 <h3>studio space</h3>
                 <img src={m1} alt="m1" />
                </div>
                
                 <div className='card_col'>
                    <h3>Location</h3>
                     <img src={m2} alt="m1" />
                 </div>
                
                <div className='card_col'>
                    <h3>SETS</h3>
                     <img src={m3} alt="m1" />
                 </div>
                
                 <div className='card_col'>
                    <h3>PROPS</h3>
                     <img src={m4} alt="m1" />
                 </div>
                
                 <div className='card_col'>
                    <h3>CREW</h3>
                     <img src={m5} alt="m1" />
                 </div>
                
                 <div className='card_col'>
                    <h3>GEAR</h3>
                     <img src={m6} alt="m1" />
                 </div>
                
                 <div className='card_col'>
                    <h3>GEAR</h3>
                     <img src={m7} alt="m1" />
                 </div>
                
                 <div className='card_col'>
                    <h3>STYLIST</h3>
                     <img src={m8} alt="m1" />
                 </div>
            
            </div>
           
            <p>Welcome to Trailblazer Films, where creativity takes center stage and stories come alive. As a premier production house, we specialize in crafting exhilarating action movies that push the boundaries of excitement and leave audiences on the edge of their seats. With our team of visionary filmmakers, we bring a unique blend of adrenaline-pumping thrills, captivating storytelling, and cutting-edge visual effects to deliver an unparalleled cinematic experience. Get ready to embark on a thrilling journey with us as we redefine the art of action and transport you into a world of pulse-pounding adventure.
</p>
        
        </section>

        <section className='center__main2'>
        <h2>Our Vision:</h2>
            <p>At Trailblazer Films, our vision is to be the pioneers of the film industry, leading the way in shaping the future of storytelling. We strive to create groundbreaking narratives that push the boundaries of conventional cinema, utilizing innovative techniques and technologies to captivate audiences around the world. 
</p>
           <div className='img__bg'>
            
           <h3>studio space</h3>
           </div>
           <div className='img__bg2'>
            
            <h3>Location</h3>
            </div>

            <div className='img__bg3'>
            
            <h3>sets</h3>
            </div>

            <div className='img__bg4'>
            
            <h3>props</h3>
            </div>
            <div className='img__bg5'>
            
            <h3>crew</h3>
            </div>
            <div className='img__bg6'>
            
            <h3>gear</h3>
            </div>

            <div className='img__bg7'>
            
            <h3>studio space</h3>
            </div>
            <div className='img__bg8'>
            
            <h3>studio space</h3>
            </div>

            <p>Welcome to Trailblazer Films, where creativity takes center stage and stories come alive. As a premier production house, we specialize in crafting exhilarating action movies that push the boundaries of excitement and leave audiences on the edge of their seats. With our team of visionary filmmakers, we bring a unique blend of adrenaline-pumping thrills, captivating storytelling, and cutting-edge visual effects to deliver an unparalleled cinematic experience. Get ready to embark on a thrilling journey with us as we redefine the art of action and transport you into a world of pulse-pounding adventure.
</p>
        </section>
    
    </div>
  )
}

export default Home