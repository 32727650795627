

import React from 'react'
import '../components/Styles/about.css'
import about1 from '../images/about1.png';
import about2 from '../images/about2.png';
import ab1 from '../images/ab1.png';
import ab2 from '../images/ab2.png';
import ab3 from '../images/ab3.png';
import ab4 from '../images/ab4.png'; 
import ab5 from '../images/ab5.png';


const About = () => {
  return (
    <div>
        <section className='top__section'>
            <h1>About Us</h1>
        </section>
    

    <section className='about__center'>
        <div className='row'>
           <div className='about__col'>
           <h2>What is TrailBlazer?</h2>
           <p>Trailblazer is more than just a production house – it's a beacon of creativity, a force that pioneers new frontiers in the world of storytelling. We are a team of passionate individuals who dare to challenge conventions and ignite the imagination through our films. Trailblazer is where extraordinary narratives are born, where the power of cinema is harnessed to inspire, entertain, and provoke thought. We strive to break new ground, trailblazing a path that leads to stories that leave an indelible mark on hearts and minds. With each project, we aim to redefine the boundaries of what is possible and create a lasting impact on the world of film. Welcome to Trailblazer, where imagination knows no limits.
</p>
           </div>

           <div className='about__col'>
            <img src={about1} alt="pic" />
           </div>

           <div className='about__col'>
           <img src={about2} alt="pic" />
           </div>

           <div className='about__col'>
             <h2>Our Mission</h2>
             <p>At Trailblazer Films, our mission is to ignite the cinematic landscape with compelling stories that captivate, inspire, and leave a lasting impact. We are committed to pushing the boundaries of creativity and innovation, constantly seeking new ways to engage audiences and challenge the status quo. Through thought-provoking narratives, breathtaking visuals, and powerful storytelling, we aim to evoke emotions, provoke conversations, and spark positive change in society. Our mission is to trailblaze a path of exceptional filmmaking, leaving a legacy of unforgettable stories that resonate with people from all walks of life. Together, let's embark on a journey that celebrates the art of storytelling and shapes the future of cinema.
</p>
           </div>
        </div>
    </section>

    <section className='about__center' style={{display:"none"}}>
          <div className='row'>
            
            <div className='last__col'>
9            <img src={ab1} alt="pic" />
            </div>
           
            <div className='last__col'>
            <img src={ab2} alt="pic" />
            </div>

            <div className='last__col'>
            <img src={ab3} alt="pic" />
            </div>


            <div className='last__col'> 
            <img src={ab4} alt="pic" />
            </div>


            </div>    
    </section>
    
    <section className='about__last' style={{display:"none"}}> 
      
      <div className='row'>
       <div className='last__col1'>
        <img src={ab5} alt="pic" />
      </div>
      <div className='last__col1'>
            <img src={ab4} alt="pic" />
      </div>
      </div>
    </section>
    
    
    </div>
  )
}



export default About