import React,{useRef, useEffect, useContext} from 'react'
import {Container, Row, Button} from 'reactstrap'
import { NavLink, Link, useNavigate } from 'react-router-dom'
import logo from './logo.png'
import './header.css'

const nav__links=[
    {
        path:'/home',
        display:'Home'
    },
    {
        path:'/about',
        display:'About Us'
    },
    {
        path:'/studio',
        display:'Studio'
    },
    {
        path:'/contact',
        display:'Contact Us'
    },


]


const Header = () => {
    const headerRef = useRef(null);
    const menuRef = useRef(null);

    const stickyHeaderFunc = ()=>{
       window.addEventListener('scroll', ()=>{
        if(document.body.scrollTop > 80 || document.documentElement.scrollTop > 80)
         {
            headerRef.current.classList.add('sticky__header')
         }
         else{
            headerRef.current.classList.remove('sticky__header')
         } 
    }) 
    }

    useEffect(()=>{
      stickyHeaderFunc()  

      return window.removeEventListener('scroll', stickyHeaderFunc)
    });

    const toggleMenu =()=>  menuRef.current.classList.toggle('show__menu')

return (<header className='header' ref={headerRef}>
            <Container>
             <Row>
                 <div className="nav__wrapper d-flex align-items-center justify-content-between">

                 {/* logo */}  
                    <div className="logo">
                      <img src={logo} alt='logo'/>  
                    </div>
                 
                 {/* logo end */}

                  {/* menu start */}
                  <div className="navigation" ref={menuRef} onClick={toggleMenu}>
                      <ul className="menu d-flex align-items-center gap-5" style={{listStyle:"none"}}>
                        {
                            nav__links.map((item,index)=>(
                                <li className='nav__item' key={index}>
                                  <NavLink to={item.path} className={navClass=> navClass.isActive ? 'active__link' : ''} reloadDocument>{item.display}</NavLink>  
                                </li>
                            ))
                        }
                        </ul>                    
                  </div>
                   {/* menu end */}
                   <div className="nav__right d-flex align-items-center gap-4">
                      <div className="nav__btns d-flex align-items-center gap-4">
                   
                      </div>
                   <span className="mobile__menu" onClick={toggleMenu}>
                  <i class='ri-menu-line' style={{color:"white",fontSize:"30px"}}></i>  
                   </span>
                   
                   </div>

                 </div>  
             </Row> 
            </Container>  
         </header>
)
}

export default Header