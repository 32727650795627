import React from 'react'
import logo from '../Header/logo.png'
import './footer.css'
import { NavLink } from 'react-router-dom'

const Footer = () => {
  return (
    <div>
        <section className='footer'>
          <div className='row'>
           <div className='fcol1'>
           <img src={logo} alt='logo'/>  
           </div>
           <div className='fcol2'>
                <h2>What are you waiting for?</h2>
                <br/>
                  <NavLink className='fcol2_btn' to='contact' style={{textDecoration:"none"}} reloadDocument>Schedule a Meeting</NavLink>
                <br/><br/>
                <a style={{textDecoration:"none"}} href='https://www.facebook.com/trailblazerfilms/' target="_blank"><i style={{fontSize:"20px"}} class="ri-facebook-fill" ></i></a>
                &nbsp;&nbsp;&nbsp;&nbsp;
                 <a  style={{textDecoration:"none"}} href='https://twitter.com/TBFilmsllc'  target="_blank"><i  style={{fontSize:"20px"}} class="ri-twitter-fill" ></i></a>
                     <br /><br />
</div>
          </div>
        </section>
    </div>
  )
}

export default Footer